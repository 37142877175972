import { readableFontColor } from '../../../utils/colors';

const getBackgroundColor = theme => (
  readableFontColor(theme.consumer?.followPrompt.backgroundColor || theme.palette.primary.main, theme.palette.common.black)
);

const getTextColor = theme => (
  readableFontColor(theme.consumer?.followPrompt.backgroundColor || theme.palette.primary.main, theme.palette.text.primary)
);

export default theme => ({
  button: {
    '&.Mui-disabled': {
      opacity: theme.palette.action.disabledOpacity,
    },
    '&:hover': {
      backgroundColor: getBackgroundColor(theme),
      opacity: 0.8,
    },
    backgroundColor: `${getBackgroundColor(theme)} !important`,
    borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
    color: `${readableFontColor(getBackgroundColor(theme), theme.palette.common.white)} !important`,
    padding: theme.spacing(1),
    width: '100%',
  },
  buttonContainer: {
    padding: '0 !important',
    width: '100%',
  },
  buttonText: {
    textTransform: 'capitalize',
  },
  cardContent: {
    padding: `${theme.spacing(0, 1, 0.5, 1)} !important`,
  },
  closeButton: {
    color: getTextColor(theme),
    fontWeight: 'normal',
    margin: theme.spacing(1),
    position: 'absolute',
    right: theme.spacing(0),
    textAlign: 'center',
    textShadow: 'none',
    top: theme.spacing(0),
    zIndex: 2,
  },
  collapse: {
    width: '100%',
  },
  collapseButton: {
    color: readableFontColor(theme.palette.primary.main, theme.palette.text.primary),
    margin: 'auto',
    padding: 0,
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      bottom: 0,
      right: 0,
    },
    bottom: theme.spacing(4),
    left: 'auto',
    position: 'fixed',
    right: theme.spacing(4),
    top: 'auto',
    zIndex: theme.zIndex.appBar,
  },
  containerInner: {
    backgroundColor: theme.consumer?.followPrompt.backgroundColor || theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    color: theme.consumer?.followPrompt.color || theme.palette.text.primary,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 0,
    maxWidth: '500px',
    padding: theme.spacing(0, 2.5),
    position: 'relative',
    textAlign: 'center',
  },
  divider: {
    borderBottom: `1px solid ${getTextColor(theme)}`,
    lineHeight: '0.1em',
    margin: theme.spacing(4, 0),
    textAlign: 'center',
    width: '100%',
  },
  dividerText: {
    backgroundColor: theme.consumer?.followPrompt.backgroundColor || theme.palette.primary.main,
    color: getTextColor(theme),
    padding: theme.spacing(0, 1),
  },
  emailField: {
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderRadius: 0,
    borderTopLeftRadius: theme.shape.borderRadius,
    color: readableFontColor(theme.palette.primary.light, theme.palette.text.primary),
    height: '100%',
    width: '100%',
  },
  emailFieldContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '175%',
    },
    borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
    margin: 0,
    width: '200%',
  },
  emailFormContainer: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: theme.shape.borderRadius,
    display: 'inline-flex',
    marginTop: theme.spacing(2),
    overflow: 'hidden',
    width: '100%',
  },
  facebookButton: {
    '& svg': {
      color: '#395697 !important',
    },
  },
  heading: {
    color: getTextColor(theme),
    fontSize: theme.typography.h5.fontSize,
    padding: theme.spacing(4, 0, 0),
    textAlign: 'center',
  },
  signInLinkButton: {
    boxShadow: theme.shadows[2],
    margin: theme.spacing(1, 0),
    width: '100%',
  },
  socialButton: {
    '& svg': {
      height: theme.spacing(4),
      top: 'auto',
      width: theme.spacing(3.5),
    },
    '&:hover': {
      backgroundColor: `${theme.palette.common.white} !important`,
      opacity: 0.8,
    },
    backgroundColor: `${theme.palette.common.white} !important`,
    color: theme.palette.common.black,

  },
  socialButtonWrapper: {
    paddingBottom: theme.spacing(2),
  },
  subheading: {
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
    color: getTextColor(theme),
    padding: `${theme.spacing(2, 0)} !important`,
    textAlign: 'center',
  },
  title: {
    fontSize: theme.typography.h5.fontSize,
    justifyContent: 'center',
  },
});
