import PropTypes from 'prop-types';
import React from 'react';
import { compose } from '@shakacode/recompose';
import { Button, Icon, Tooltip } from '@popmenu/common-ui';
import { FacebookIcon, GoogleIcon } from '@popmenu/web-icons';

import { withIntl } from '../../utils/withIntl';
import withSocialButton from '../../utils/withSocialButton';
import { classNames, withStyles } from '../../utils/withStyles';
import signInStyles from '../../assets/jss/shared/signInStyles';

const ICON_MAP = {
  facebook: FacebookIcon,
  google: GoogleIcon,
};

const SocialButton = props => (
  <Tooltip title={props.tooltip || undefined}>
    <Button
      role="link"
      aria-label={props['aria-label']}
      disableElevation
      fullWidth
      className={classNames(
        props.classes.socialButton,
        props.classes[`button-${props.socialType}`],
      )}
      disabled={props.loading}
      startIcon={(
        <Icon icon={ICON_MAP[props.socialType.split('_')[0]]} />
      )}
      onClick={() => {
        if (!props.loading && !props.preview) {
          if (props.customFollowPath) {
            window.open(props.customFollowPath, '_blank').focus();
          } else {
            props.openSocialLogin();
          }
        }
      }}
      size={props.size}
      variant="contained"
      {...props.buttonProps}
    >
      {props.children ? props.children : props.t(`sessions.omniauth.${props.socialType}`)}
    </Button>
  </Tooltip>
);

SocialButton.defaultProps = {
  'aria-label': null,
  buttonProps: null,
  children: null,
  loading: false,
  scope: null,
  size: 'large',
  tooltip: undefined,
};

SocialButton.propTypes = {
  'aria-label': PropTypes.string,
  buttonProps: PropTypes.object,
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  openSocialLogin: PropTypes.func.isRequired,
  scope: PropTypes.string,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  socialType: PropTypes.oneOf(['facebook_type', 'google_type', 'twitter_type']).isRequired,
  t: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
};

export default compose(
  withStyles(signInStyles),
  withIntl,
  withSocialButton,
)(SocialButton);
