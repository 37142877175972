import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { compose, mapProps } from '@shakacode/recompose';
import { connect, useSelector } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import { Box, Card, CardHeader, CardContent, Grid, IconButton, Typography, Icon } from '@popmenu/common-ui';
import { ChevronUp, X as XIcon } from '@popmenu/web-icons';
import { Collapse } from '@material-ui/core';

import { createEvent } from '~/utils/eventable';
import { withWindowContext } from '../../../shared/WindowProvider';
import { withWindowSizeContext } from '../../../shared/WindowSizeProvider';
import { classNames, withStyles } from '../../../utils/withStyles';
import { useFeatureFlags } from '../../../utils/featureFlagsContext';
import consumerUserByEmailQuery from '../../../libs/gql/queries/users/consumerUserByEmailQuery.gql';
import createUserMutation from '../../../libs/gql/mutations/users/createUserMutation.gql';
import followPromptV2Styles from './styles';
import { closeFollowPromptV2, openVipV2Modal, setDidSubmitEmailWithFollowPrompt, setVipData } from '../../../shared/ModalActions';
import { withCurrentSession } from '../../../shared/CurrentSessionProvider';
import { withRestaurant } from '../../../utils/withRestaurant';
import { withIntl } from '../../../utils/withIntl';

import BasicForm, { SubmitGroup, TextFieldGroup } from '../../../admin/shared/forms/BasicForm';
import SocialButton from '../../../shared/sessions/SocialButton';
import { AH, AHLevelProvider } from '../AccessibleHeading';

const BECOME_VIP_DISMISSED = 'becomeVipDismissed';

const FollowPromptV2 = (props) => {
  const location = useLocation();
  const { pathname } = location;
  const showFollowPromptV2 = useSelector(state => state.modals.showFollowPromptV2);
  const [useThirdPartyAuth, setUseThirdPartyAuth] = useState(false);
  const [isExpanded, setIsExpanded] = useState(!props.isMobile);
  const { isFeatureActive } = useFeatureFlags();
  const isFacebookOauthDisabled = isFeatureActive('facebook_oauth_disabled');

  useEffect(() => {
    if (props.isLoggedIn && useThirdPartyAuth) {
      setUseThirdPartyAuth(false);
      props.setVipData({ userEmail: props.user.email });
      props.openVipV2Modal();
    }
  }, [props.isLoggedIn, useThirdPartyAuth, props]);

  const { activeBecomeVipMarketingOffer, displayVipPopup, followHeadingCta, followSubheadingCta, followCollapseSubheadingCta } = props.restaurant;

  // don't show if restaurant disabled display or user is on consumer feedback page
  if (!displayVipPopup || pathname.includes('feedback')) {
    return null;
  }

  // Hide if prompt was dismissed in tab
  try {
    if (sessionStorage) {
      if (sessionStorage.getItem(BECOME_VIP_DISMISSED)) {
        return null;
      }
    }
  } catch (err) {
    console.warn(err);
  }

  const dismissPrompt = () => {
    createEvent({
      eventableType: 'BecomeVipV2',
      eventType: 'v2_pop_up_close',
    });
    props.closeFollowPromptV2();

    // Store if user dismissed prompt in session storage
    try {
      if (sessionStorage) {
        sessionStorage.setItem(BECOME_VIP_DISMISSED, true);
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const collapseControl = () => {
    if (props.isMobile && !isExpanded) {
      setIsExpanded(!isExpanded);
    }
  };

  // New showVipPromptOnHomePageOnly logic requires that in the case the toggle is disabled, to not show the prompt on online order page or ordering event page. Ordering event pages always have '/orders/' in the url.
  const dontShowVipModalRules = [ // CAUTION: keep synced with ScrolledModalsOpener.jsx
    props.isLoggedIn || !showFollowPromptV2 || !props.scrolledHeader || props.bypassPrompt || props.isSessionLoading,
    props.restaurant.featureSetting.isShowVipPromptOnHomePageOnlyEnabled && props.location.pathname !== '/',
    !props.restaurant.featureSetting.isShowVipPromptOnHomePageOnlyEnabled && props.location.pathname === props.restaurant.onlineOrderingDefaultUrl,
    !props.restaurant.featureSetting.isShowVipPromptOnHomePageOnlyEnabled && props.location.pathname.includes('/orders/'),
  ];
  const isTrue = value => (value);

  if (dontShowVipModalRules.some(isTrue)) {
    return null;
  }

  const followHeading = activeBecomeVipMarketingOffer?.title ? activeBecomeVipMarketingOffer.title : followHeadingCta;
  const followSubheading = activeBecomeVipMarketingOffer?.description ? activeBecomeVipMarketingOffer.description : (props.isMobile && !isExpanded ? followCollapseSubheadingCta : followSubheadingCta);

  return (
    <Card
      className={classNames('pm-follow-v2', 'pm-follow', props.classes.container)}
      onClick={collapseControl}
      variant="outlined"
    >
      <div className={props.classes.containerInner}>
        <CardHeader
          action={(
            <IconButton
              aria-label="Dismiss Become a VIP Prompt"
              className={props.classes.closeButton}
              onClick={dismissPrompt}
              variant="contained"
            >
              <Icon icon={XIcon} />
            </IconButton>
          )}
          className={props.classes.heading}
          title={<AH typography className={props.classes.title} variant="h4">{followHeading}</AH>}
        />
        <AHLevelProvider>
          <CardContent className={props.classes.cardContent}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body1" className={props.classes.subheading}>
                  {followSubheading}
                </Typography>
              </Grid>
              {!isExpanded && props.isMobile && (
                <IconButton aria-label="Open Become a VIP Prompt" onClick={collapseControl} className={props.classes.collapseButton}>
                  <Icon icon={ChevronUp} />
                </IconButton>
              )}
              <Collapse in={isExpanded} className={props.classes.collapse}>
                <Grid item xs={12} className={props.classes.emailFormContainer}>
                  <BasicForm
                    mutate={!props.restaurant.customFollowPath && {
                      mutation: consumerUserByEmailQuery,
                      onCompleted: () => {
                        props.setDidSubmitEmailWithFollowPrompt(true);
                        props.openVipV2Modal();
                        createEvent({
                          eventableType: 'BecomeVipV2',
                          eventType: 'v2_pop_up_join_for_free',
                        });
                      },
                      toVariables: variables => ({
                        restaurantId: props.restaurant.id,
                        userEmail: variables.email,
                      }),
                    }}
                    onSubmit={({ email }) => {
                      if (props.restaurant.customFollowPath) {
                        window.open(props.restaurant.customFollowPath, '_blank').focus();
                        props.closeFollowPromptV2();
                      } else {
                        props.setVipData({ isBecomeVipModal: true, userEmail: email });
                      }
                    }}
                    style={{ display: 'flex', width: '100%' }}
                  >
                    {({ values }) => (
                      <React.Fragment>
                        <TextFieldGroup
                          field="email"
                          type="email"
                          FormGroupProps={{ className: props.classes.emailFieldContainer }}
                          InputProps={{ className: props.classes.emailField, 'data-cy': 'email_input' }}
                          placeholder={props.t('users.email_address')}
                        />
                        <SubmitGroup
                          className={props.classes.buttonContainer}
                          ButtonProps={{
                            'aria-label': 'Join for free',
                            className: props.classes.button,
                          }}
                          inline
                          title={(
                            <span className={props.classes.buttonText}>
                              <FormattedMessage id="follow.join_button" defaultMessage="Join for free" />
                            </span>
                          )}
                          disabled={!values.email}
                        />
                      </React.Fragment>
                    )}
                  </BasicForm>
                </Grid>
                <Grid item xs={12}>
                  <Box className={props.classes.divider}>
                    <span className={props.classes.dividerText}>
                      <FormattedMessage id="follow.prompt.divider_text" defaultMessage="or join with" />
                    </span>
                  </Box>
                </Grid>
                <Grid item xs={12} className={props.classes.socialButtonWrapper}>
                  <BasicForm
                    labelPosition="top"
                    mutate={{
                      mutation: createUserMutation,
                      toVariables: ({ facebookCode, googleCode }) => ({
                        facebookCode,
                        googleCode,
                      }),
                    }}
                  >
                    {({ submitManual }) => (
                      <Grid container spacing={2}>
                        {!isFacebookOauthDisabled && (
                          <Grid item xs={6}>
                            <SocialButton
                              aria-label="Sign up to our VIP program using Facebook (opens in a new window)"
                              baseLocale="social_accounts.social_types.facebook_type"
                              buttonProps={{ className: classNames(props.classes.facebookButton, props.classes.socialButton), variant: 'outlined' }}
                              size="medium"
                              socialType="facebook_type"
                              submit={(e, variables) => {
                                if (!props.restaurant.customFollowPath) {
                                  createEvent({
                                    eventableType: 'BecomeVipV2',
                                    eventType: 'v2_pop_up_join_facebook',
                                  });
                                  setUseThirdPartyAuth(!!variables.facebookCode);
                                  submitManual(variables);
                                }
                              }}
                              tokenName="facebook_type"
                              customFollowPath={props.restaurant.customFollowPath}
                            >
                              <FormattedMessage
                                id="sessions.omniauth.facebook"
                                defaultMessage="Facebook"
                              />
                            </SocialButton>
                          </Grid>
                        )}
                        <Grid item xs={isFacebookOauthDisabled ? 12 : 6}>
                          <SocialButton
                            aria-label="Sign up to our VIP program using Google (opens in a new window)"
                            baseLocale="social_accounts.social_types.google_type"
                            buttonProps={{ className: props.classes.socialButton, variant: 'outlined' }}
                            size="medium"
                            socialType="google_type"
                            submit={(e, variables) => {
                              if (!props.restaurant.customFollowPath) {
                                createEvent({
                                  eventableType: 'BecomeVipV2',
                                  eventType: 'v2_pop_up_join_google',
                                });
                                setUseThirdPartyAuth(!!variables.googleCode);
                                submitManual(variables);
                              }
                            }}
                            tokenName="google_type"
                            customFollowPath={props.restaurant.customFollowPath}
                          >
                            <FormattedMessage
                              id="sessions.omniauth.google"
                              defaultMessage="Google"
                            />
                          </SocialButton>
                        </Grid>
                      </Grid>
                    )}
                  </BasicForm>
                </Grid>
              </Collapse>
            </Grid>
          </CardContent>
        </AHLevelProvider>
      </div>
    </Card>
  );
};

FollowPromptV2.propTypes = {
  bypassPrompt: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  closeFollowPromptV2: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isSessionLoading: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  openVipV2Modal: PropTypes.func.isRequired,
  restaurant: PropTypes.shape({
    customFollowPath: PropTypes.string,
    displayVipPopup: PropTypes.bool,
    followButtonCta: PropTypes.string,
    followHeadingCta: PropTypes.string,
    followSubheadingCta: PropTypes.string,
  }).isRequired,
  scrolledHeader: PropTypes.bool.isRequired,
  setDidSubmitEmailWithFollowPrompt: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  connect(state => ({
    bypassPrompt: state.menuItemCart.bypassPrompt,
  }), {
    closeFollowPromptV2,
    openVipV2Modal,
    setDidSubmitEmailWithFollowPrompt,
    setVipData,
  }),
  withIntl,
  withRestaurant,
  withWindowContext,
  withWindowSizeContext,
  mapProps(({ window, windowSize, ...props }) => ({
    ...props,
    isMobile: windowSize.isMobile,
    scrolledHeader: window.scrolledHeader,
  })),
  withStyles(followPromptV2Styles),
  withCurrentSession,
  withRouter,
  mapProps(({ currentSession, ...props }) => ({
    isLoggedIn: !!currentSession.user,
    isSessionLoading: !currentSession.id,
    user: currentSession.user,
    ...props,
  })),
)(FollowPromptV2);
