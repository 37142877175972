import type { StyleRules, Theme } from '@material-ui/core/styles';

const signInStyles = (theme: Theme) => ({
  'button-facebook_type': {
    '&:hover': {
      backgroundColor: '#FFFFFF !important',
      opacity: 0.8,
    },
    backgroundColor: '#FFFFFF !important',
    border: '1px solid #444444',
    color: '#444444 !important',
  },
  'button-google_type': {
    '&:hover': {
      backgroundColor: '#FFFFFF !important',
      opacity: 0.8,
    },
    backgroundColor: '#FFFFFF !important',
    border: '1px solid #444444',
    color: '#444444 !important',
  },
  'button-twitter_type': {
    '&:hover': {
      backgroundColor: '#FFFFFF !important',
      opacity: 0.8,
    },
    backgroundColor: '#FFFFFF !important',
    border: '1px solid #444444',
    color: '#444444 !important',
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  disclaimer: {
    color: 'inherit',
    fontSize: '0.75em',
    marginTop: theme.spacing(2),
  },
  passwordField: {
    width: '100%',
  },
  selectGroupHeight: {
    height: theme.spacing(4.5),
  },
  separator: {
    border: '1px solid #e6e6e6',
    margin: theme.spacing(2, 0),
  },
  signInLinkButton: {
    '& button': {
      marginTop: theme.spacing(2),
    },
    border: '1px solid black',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1, 0),
    textAlign: 'center',
    width: '100%',
  },
  signInLinks: {
    '& button': {
      marginTop: theme.spacing(2),
    },
    textAlign: 'center',
  },
  signInSubtitle: {
    fontSize: '13px',
    marginBottom: 0,
    marginTop: theme.spacing(-2),
  },
  signInTitle: {
    fontSize: '24px',
    marginBottom: theme.spacing(4),
  },
  socialButton: {
    '& svg': {
      height: '1em',
      width: '1em',
    },
  },
  socialButtons: {
    '& button': {
      color: 'white',
      fontSize: '14px !important',
      letterSpacing: '0 !important',
      marginTop: theme.spacing(2),
      textTransform: 'none !important',
    },
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(0),
    textAlign: 'center',
  },
  socialSeparator: {
    alignItems: 'center',
    display: 'flex',
    inset: 0,
    position: 'absolute',
  },
  socialSeparatorContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 0',
    position: 'relative',
  },
  socialSeparatorLine: {
    borderTop: '1px solid black',
    width: '100%',
  },
  socialSeparatorOld: {
    '&:after': {
      content: '" - "',
    },
    '&:before': {
      content: '" - "',
    },
    fontSize: '1.25em',
    fontStyle: 'italic',
    margin: theme.spacing(-1, 0, -1, 0),
  },
  socialSeparatorText: {
    backgroundColor: 'white',
    display: 'inline-flex',
    justifyContent: 'center',
    padding: theme.spacing(0, 2),
    position: 'relative',
  },
  termsButton: {
    color: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    minHeight: 0,
    padding: 0,
    textDecoration: 'underline',
    textTransform: 'none',
  },
} as const satisfies StyleRules);

export default signInStyles;
