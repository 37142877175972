import React from 'react';

import { getParam, toQS } from './urls';
import { withPopmenuConfig } from './withPopmenuConfig';
import { withIntl } from './withIntl';

const openWindow = (url, w, h) => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const dualScreenLeft = window.screenLeft || window.screen.left;
  const dualScreenTop = window.screenTop || window.screen.top;
  const left = ((width / 2) - (w / 2)) + dualScreenLeft;
  const top = ((height / 2) - (h / 2)) + dualScreenTop;
  const options = `menubar=no,location=no,resizable=yes,scrollbars=no,width=${w},height=${h},left=${left},top=${top}`;
  return window.open(url, '_blank', options);
};

/*
  PropsTypes:
  scope: PropTypes.string,
  socialType: PropTypes.oneOf(['facebook', 'google', 'twitter']).isRequired,
  submit: PropTypes.func.isRequired,
*/
const withSocialButton = WrappedComponent => withIntl(withPopmenuConfig(class extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.listener = null;
    this.openSocialLogin = this.openSocialLogin.bind(this);
    this.pingWindow = this.pingWindow.bind(this);
    this.submitToken = this.submitToken.bind(this);
  }

  // If postRobot fails the user is redirected back to the sign in route
  // with the token in the hash- so on mount, see if the token is available
  // and immediately fire the submit handler
  componentDidMount() {
    if (!this.state.submitted) {
      const token = getParam(`${this.props.tokenName}_token`);
      if (token) {
        this.submitToken(null, token);
        window.location.hash = '';
        if (window.history) {
          window.history.replaceState('', document.title, window.location.pathname);
        }
      }
    }
  }

  // Call submit function provided to the component
  submitToken(e, token) {
    const variables = {};
    variables[this.props.t(`${this.props.baseLocale}.sign_in_variable`)] = token;
    this.props.submit(e, variables);
    this.listener = null;
    this.setState({ submitted: true });
  }

  pingWindow(target) {
    console.log('[POPMENU] Initializing postRobot listener...');
    // Prevent server-side error when importing DOM-dependent library
    if (typeof window !== 'undefined') {
      this.listener = import(/* webpackChunkName: "postRobot" */ 'post-robot').then(postRobot => postRobot.once('ping', { window: target }, () => {
        postRobot.send(target, 'getToken', {}, { domain: this.props.popmenuConfig.signInUrl }).then((e) => {
          if (e.data.token) {
            this.submitToken(e, e.data.token);
          }
          target.close();
        }).catch((err) => {
          console.log(`Unhandled error: ${err}`);
        });
      }));
    }
  }

  openSocialLogin() {
    const w = Math.min(window.innerWidth, 1000);
    const h = Math.min(window.innerHeight, 820);
    const qs = {
      access_type: this.props.t(`${this.props.baseLocale}.access_type`),
      prompt: this.props.t(`${this.props.baseLocale}.prompt`),
      scope: this.props.scope,
    };
    const signInUrl = `${this.props.popmenuConfig.signInUrl}${this.props.t(`${this.props.baseLocale}.sign_in_path`)}?${toQS(qs)}`;
    console.log(`Opening window w/ sign in url: ${signInUrl}`);
    const target = openWindow(signInUrl, w, h);
    this.pingWindow(target);
  }

  render() {
    const { submit, ...props } = this.props;
    return (
      <WrappedComponent
        {...props}
        openSocialLogin={this.openSocialLogin}
      />
    );
  }
}));

export default withSocialButton;
